import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import AES from 'crypto-js/aes';
import CFB from 'crypto-js/mode-cfb'; //CFB模式
import UTF8 from 'crypto-js/enc-utf8';
import Nopadding from 'crypto-js/pad-nopadding'; //这里使输出HEX格式
import { randomString } from '@/utils/stringFun';
export var encrypt = function encrypt(data) {
  var key = UTF8.parse("F85BF211E3B741E4BB8E804FBCD005C7");
  var iv_str = randomString(16);
  var iv = UTF8.parse(iv_str);
  data = UTF8.parse(iv_str + data);
  // 加密
  var ciphertext = AES.encrypt(data, key, {
    iv: iv,
    mode: CFB,
    padding: Nopadding
  });
  var result = ciphertext.ciphertext.toString(); //这里返回的是HEX格式
  return result;
};